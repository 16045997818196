
import React from "react"

function EmbededMap(props){
	return (
		<div className="map-wrapper">
			<iframe className="embeded-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1912.297004331131!2d-149.53208948384477!3d61.360681082415155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x56c8ebe9935a1895%3A0xfa1dc07d7767fbab!2sSaint%20John%20Orthodox%20Cathedral!5e0!3m2!1sen!2sus!4v1582229768372!5m2!1sen!2sus"></iframe>
		</div>	
	)
}

export default EmbededMap


//			<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7649.1880130256695!2d-149.5299008!3d61.3606811!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8e88e1166f6ecd93!2sSt.%20John&#39;s%20Orthodox%20Christian%20School!5e0!3m2!1sen!2sus!4v1579213381569!5m2!1sen!2sus" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
