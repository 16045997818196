import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/contact.css"
import EmbededMap from "../../components/home/EmbededMap"
import VisitUs from "../../components/footer/VisitUs"
import ContactUs from "../../components/footer/ContactUs"

import Car from '@material-ui/icons/DirectionsCar';


function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/contact/St. James.jpg" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        },
	        site {
	        	siteMetadata{
				    aboutUsMetadata {
				      links {
				        link
				        url
				      }
				      sidebarTextblocks {
				        body
				        header
				      }
				      subheader {
				        main
				        sub
				      }
				    }
				}
			  }
			}
	`

	let data = useStaticQuery(query)

	let images = data.allFile

	let subheaderData = data.site.siteMetadata.aboutUsMetadata.subheader; 

	const church = images.edges[0].node
	const location = ""
	

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="si te-content contact-us">
				<div>
					<Subheader data={subheaderData} />
				</div>

				<div className="col-1-2">
				<Img fluid={church.childImageSharp.fluid} />

				</div>
				<div className="col-2-2">
				<div className="contact-block"><b>Website:</b><p><a href="//stjohnalaska.org" target="_blank">www.stjohnalaska.org</a></p></div>
				<div className="contact-block"><b>Email:</b><p><a href="mailto: stjohnalaska@aol.com" target="_blank">stjohnalaska@aol.com</a></p></div>
				<div className="contact-block">
					<b>Phone:</b>
					<div>
					Church Office: 907-696-2002
					</div>
					<div>
					School Office: 907-696-3920
					</div>
					<div>
					St. James House: 907-696-2972
					</div>
					<div>
					Fax: 907-696-5555
					</div>
				</div>
				<div className="contact-block">
					<b>Mailing Address:</b>
					<div> P.O. Box 771108</div>
					<div>Eagle River, AK 99577</div>
				</div>
				<div className="contact-block">
					<b>Physical Address:</b>
					<div>18936 Monastery Drive</div>
					<div>Eagle River, Alaska</div><br/>
					<a className="download-btn church-directions-btn" href="https://www.google.com/maps/place/18936+Monastery+Dr,+Eagle+River,+AK+99577/data=!4m2!3m1!1s0x56c8ebe9ecab05f1:0xd03daa9ff9a308b?sa=X&ved=2ahUKEwj5te3f5PfmAhWGrZ4KHbZiB_gQ8gEwAHoECAsQAQ" target="_blank">Directions <Car  style={{ fontSize: 25, marginLeft:"10px" }}/></a>
				</div>

				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 